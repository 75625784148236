import axios from 'axios'
import {
  AuthModel,
  DocumentTypeResponseModel,
  LocalizacionesResponseModel,
  PaisesResponseModel,
  ResponseMsgModel,
  StepsResponseModel,
  UserInteractionModel,
  UserModel,
  UserRegistration,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL2
const API_URL_APLICATIVO = process.env.REACT_APP_API_URL_APLICATIVO

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/getUserByToken`
export const GET_USER_BY_ACCESSTOKEN_APLICATIVO_URL = `${API_URL_APLICATIVO}/auth/getUserByToken`
export const LOGIN_URL = `${API_URL}/auth/login`
export const LOGIN_APLICATIVO_URL = `${API_URL_APLICATIVO}/auth/login`
export const REGISTER_URL = `${API_URL}/auth/register`
// export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const RECUPERARPASSWORD_URL = `${API_URL}/auth/sendVerificationCodeEmail`

export const CAMBIARPASSWORD_URL = `${API_URL}/auth/changePassword`

export const CONFIRMEMAIL_URL = `${API_URL}/auth/checkEmail`

export const SENDVERIFICATIONEMAIL_URL = `${API_URL}/register/sendVerificationCodeEmail`
export const VERIFYCODEEMAIL_URL = `${API_URL}/register/verificationCode`

export const GETPAISES_URL = `${API_URL}/register/findAllCountries`
export const GET_TIPO_DOCUMENTOS_URL = `${API_URL}/register/tipoDocumentoFindAll`
export const GET_TIPO_SERVICIOS_URL = `${API_URL}/cat_tipo_servicio/find_all`
export const GETLOCALIZACIONES_URL = `${API_URL}/register/findAllLocalizaciones`

//Interacción
export const CREATE_USER_URL = `${API_URL}/register/createUser`
export const ADD_ACCOUNT_TYPE_URL = `${API_URL}/register/addAccountType`
export const ADD_COUNTRY_TYPE_URL = `${API_URL}/register/addCountryType`
export const SEND_VERIFICATION_CODE_SMS_URL = `${API_URL}/register/sendVerificationCodeSms`
export const CONFIRMATION_CODE_SMS_URL = `${API_URL}/register/confirmationCodeSms`
export const ADD_PASSWORD_URL = `${API_URL}/register/addPassword`
export const ADD_IDENTITY_URL = `${API_URL}/register/addIdentity`
// export const ADD_IDENTITY_DOCUMENT_URL = `${API_URL}/register/saveDocumentIdentity`
export const ADD_SERVICE_DOCUMENT_URL = `${API_URL}/register/saveDocumentService`
export const ADD_ADDRESS_URL = `${API_URL}/register/addAddress`

export const ACCEPT_VALIDATION_URL = `${API_URL}/register/acceptValidationProcess`
export const ADD_DATA_DOCUMENT_URL = `${API_URL}/register/addDataDocumentIdentity`
export const ADD_IDENTITY_DOCUMENT_URL = `${API_URL}/register/saveDocumentIdentity`
export const ADD_SELFIE_DOCUMENT_URL = `${API_URL}/register/saveDocumentSelfie`

//Para configuración de AuthStepper
// export const FIND_DOMAIN_URL = `${API_URL}/register/findOrgDominioWeb`
export const FIND_ORBOARDING_VISTAS_URL = `${API_URL}/register/findOrboardingVistas`

// Server should return AuthModel
export function login(User: string | undefined, Password: string | undefined, Dominio: string) {
  return axios.post<AuthModel>(LOGIN_APLICATIVO_URL, {
    User,
    Password,
    Dominio,
  })
}

// Server should return AuthModel
export function register(userData: UserRegistration) {
  return axios.post<ResponseMsgModel>(REGISTER_URL, userData)
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_APLICATIVO_URL, {
    Token: token,
  })
}

//confirmar email HeoMoney

export function activarUsuario(Email: string, CodigoConfirmacion: String, Dominio: string) {
  return axios.post<ResponseMsgModel>(CONFIRMEMAIL_URL, {
    Email,
    CodigoConfirmacion,
    Dominio,
  })
}

export function recuperarcontraseña(Email: string, Dominio: string) {
  return axios.post<ResponseMsgModel>(RECUPERARPASSWORD_URL, {
    Email,
    Dominio,
  })
}

export function cambiarcontraseña(
  Email: string,
  NewPassword: string,
  CodigoVerificacion: string,
  Dominio: string
) {
  return axios.post<ResponseMsgModel>(CAMBIARPASSWORD_URL, {
    Email,
    NewPassword,
    CodigoVerificacion,
    Dominio,
  })
}

export function enviarCodigoEmail(Email: string, DominioWeb: string) {
  return axios.post<ResponseMsgModel>(SENDVERIFICATIONEMAIL_URL, {
    Email,
    DominioWeb,
  })
}

export function verifyCode(Email: string, CodigoConfirmacion: string, DominioWeb: string) {
  return axios.post<UserInteractionModel>(VERIFYCODEEMAIL_URL, {
    Email,
    CodigoConfirmacion,
    DominioWeb,
  })
}

export function getLocalizaciones(Pais: string, Id: number) {
  return axios.post<LocalizacionesResponseModel>(GETLOCALIZACIONES_URL, {
    Pais,
    Id,
  })
}

export function getPaises() {
  return axios.post<PaisesResponseModel>(GETPAISES_URL, {
    cantidad_max: '0',
    Language: 'ES',
    filters: [
      {
        campo: 'IdPais',
        operador: 'EQ',
        tipo: 'string',
        valor1: 'PE',
        valor2: '',
      },
      {
        campo: 'IdPais',
        operador: 'EQ',
        tipo: 'string',
        valor1: 'MX',
        valor2: '',
      },
    ],
  })
}

export function getTiposDocumentos(isoPais: string, view: string) {
  return axios.post<DocumentTypeResponseModel>(GET_TIPO_DOCUMENTOS_URL, {
    cantidad_max: '0',
    Language: 'ES',
    filters: [
      {
        campo: 'IdPais',
        operador: 'EQ',
        tipo: 'string',
        valor1: isoPais,
        valor2: '',
      },
      {
        campo: 'ViewFront',
        operador: 'EQ',
        tipo: 'numeric2',
        valor1: view,
        valor2: '',
      },
    ],
  })
}

export function getTiposServicios(isoPais: string) {
  return axios.post<DocumentTypeResponseModel>(GET_TIPO_DOCUMENTOS_URL, {
    cantidad_max: '0',
    Language: 'ES',
    filters: [
      {
        campo: 'IdPais',
        operador: 'EQ',
        tipo: 'string',
        valor1: isoPais,
        valor2: '',
      },
      {
        campo: 'ViewFront',
        operador: 'EQ',
        tipo: 'numeric2',
        valor1: '2',
        valor2: '',
      },
    ],
  })
}

//Flujo de interacción
export function createUser(Email: string, DominioWeb: string) {
  return axios.post<ResponseMsgModel>(CREATE_USER_URL, {
    Email,
    DominioWeb,
  })
}

export function addAccountType(
  Email: string,
  DominioWeb: string,
  IdTipoUsuario: number
) {
  return axios.post<ResponseMsgModel>(ADD_ACCOUNT_TYPE_URL, {
    Email,
    DominioWeb,
    IdTipoUsuario,
  })
}

export function addCountry(Email: string, CodigoPais: string, DominioWeb: string) {
  return axios.post<ResponseMsgModel>(ADD_COUNTRY_TYPE_URL, {
    Email,
    CodigoPais,
    DominioWeb
  })
}

export function sendVerificationCodeSms(Email: string, Telefono: string, DominioWeb: string) {
  return axios.post<ResponseMsgModel>(SEND_VERIFICATION_CODE_SMS_URL, {
    Email,
    Telefono,
    DominioWeb,
  })
}

export function confirmationCodeSms(Email: string, CodigoConfirmacion: string, DominioWeb: string) {
  return axios.post<ResponseMsgModel>(CONFIRMATION_CODE_SMS_URL, {
    Email,
    CodigoConfirmacion,
    DominioWeb,
  })
}

export function addPassword(
  Email: string,
  Password: string,
  EndRegister: boolean,
  DominioWeb: string,
) {
  return axios.post<ResponseMsgModel>(ADD_PASSWORD_URL, {
    Email,
    Password,
    EndRegister,
    DominioWeb,
  })
}

export function addIdentity(
  RazonSocial: string,
  Email: string,
  Nombre: string,
  APaterno: string,
  AMaterno: string,
  FechaNacimiento: string,
  EndRegister: boolean,
  DominioWeb: string,
  Genero: string
) {
  return axios.post<ResponseMsgModel>(ADD_IDENTITY_URL, {
    RazonSocial,
    Email,
    Nombre,
    APaterno,
    AMaterno,
    FechaNacimiento,
    EndRegister,
    DominioWeb,
    Genero,
  })
}

export function addAddress(
  Email: string,
  DominioWeb: string,
  Direccion1: string,
  Direccion2: string,
  CodigoPostal: string,
  Departamento: number,
  Provincia: number,
  Distrito: number,
  CentroPoblado: string,
  Estado: number,
  Delegacion: number,
  Colonia: string,
  Municipio: string,
  Ciudad: string,
  NroExt: string,
  NroInt: string,
  EndRegister: boolean,
) {
  return axios.post<ResponseMsgModel>(ADD_ADDRESS_URL, {
    Email,
    DominioWeb,
    Direccion1,
    Direccion2,
    CodigoPostal,
    Departamento,
    Provincia,
    Distrito,
    CentroPoblado,
    Estado,
    Delegacion,
    Colonia,
    Municipio,
    Ciudad,
    NroExt,
    NroInt,
    EndRegister
  })
}

// export function addIdentityDocument(
//   Email: string,
//   IdOrgSaas:number,
//   EndRegister: boolean,
//   Documentos:[{
//     TipoDocumento: number,
//     FileTipoMime: string,
//     FileBase64: string,
//     FileNombre: string,
//     NumeroDocumento: string,
//     FechaEmision: string,
//     FechaCaducidad: string
//   },
//   {
//     TipoDocumento: number,
//     FileTipoMime: string,
//     FileBase64: string,
//     FileNombre: string,
//     NumeroDocumento: string,
//     FechaEmision: string,
//     FechaCaducidad: string
//   }]
// ) {
//   return axios.post<ResponseMsgModel>(ADD_IDENTITY_DOCUMENT_URL, {
//     Email,
//     IdOrgSaas,
//     EndRegister,
//     Documentos
//   })
// }

export function addServiceDocument(
  Email: string,
  IdOrgSaas: number,
  EndRegister: boolean,
  Documentos: [
    {
      TipoDocumento: number
      FileTipoMime: string
      FileBase64: string
      FileNombre: string
    }
  ]
) {
  return axios.post<ResponseMsgModel>(ADD_SERVICE_DOCUMENT_URL, {
    Email,
    IdOrgSaas,
    EndRegister,
    Documentos,
  })
}

//Pasos nuevos Identidad
export function acceptValidationIdentity(Email: string, DominioWeb: string, Accept: boolean) {
  return axios.post<ResponseMsgModel>(ACCEPT_VALIDATION_URL, {
    Email,
    DominioWeb,
    Accept,
  })
}

export function addDataDocumentIdentity(
  Email: string,
  DominioWeb: string,
  TipoDocumento: number,
  NumeroDocumento: string,
  FechaEmision: string,
  FechaCaducidad: string,
  EndRegister: boolean
) {
  return axios.post<ResponseMsgModel>(ADD_DATA_DOCUMENT_URL, {
    Email,
    DominioWeb,
    TipoDocumento,
    NumeroDocumento,
    FechaEmision,
    FechaCaducidad,
    EndRegister,
  })
}

export function saveDocumentIdentity(
  Email: string,
  DominioWeb: string,
  EndRegister: boolean,
  Documentos: [
    {
      FileTipoMime: string
      FileBase64: string
      FileNombre: string
    },
    {
      FileTipoMime: string
      FileBase64: string
      FileNombre: string
    }
  ]
) {
  return axios.post<ResponseMsgModel>(ADD_IDENTITY_DOCUMENT_URL, {
    Email,
    DominioWeb,
    EndRegister,
    Documentos,
  })
}

export function addSelfieDocumentIdentity(
  Email: string,
  DominioWeb: string,
  EndRegister: boolean,
  Documentos: [
    {
      FileTipoMime: string
      FileBase64: string
      FileNombre: string
    }
  ]
) {
  return axios.post<ResponseMsgModel>(ADD_SELFIE_DOCUMENT_URL, {
    Email,
    DominioWeb,
    EndRegister,
    Documentos,
  })
}
//Para configuración de AuthStepper

export function findStepsAuthStepper(DominioWeb: string) {
  return axios.post<StepsResponseModel>(FIND_ORBOARDING_VISTAS_URL, {
    DominioWeb,
  })
}
