import axiosInstance from '../../api/axiosConfig'
import {centersFindAllResponse} from './interfaces'
//antes llamado Centros, se cambió a locales
const path = '/centros'

export const getCenters = async (): Promise<centersFindAllResponse> => {
  try {
    const response = await axiosInstance.post<centersFindAllResponse>(path + '/find_all', {
      "cantidad_max": "0",
      "Language": "ES",
      "filters": [
        {
          "campo": "0",
          "operador": "EQ | BT | GT  | LT  | GE  | LE  | Contains",
          "tipo": "date  | numeric2 | string | comboPlataforma | other | boolean",
          "valor1": "string",
          "valor2": "string"
        }
      ]
    })
    return response.data
  } catch (error) {
    console.error('Error fetching centros:', error)
    throw error
  }
}
